import { scalePreset } from '../../common/constants/defaultPresets';
import type {
  PhysicalToLogicalAlignment,
  FlexDirection,
  PhysicalAlignment,
  LogicalAlignment,
  TextAlignment,
  TextualToLogicalAlignment,
} from './StylableButton.types';
import type { ComponentLayoutProps, DS } from '@wix/editorx-core-api';

export const TestIds = {
  buttonContent: 'buttonContent',
  buttonLabel: 'stylablebutton-label',
  buttonIcon: 'stylablebutton-icon',
  animatedSvg: 'animated-svg',
  layoutPanel: {
    root: 'stylablebutton-layoutPanel',
    buttonFlowThumbnails: 'stylablebutton-layoutPanel-buttonFlow',
    buttonFlowDropdown: 'stylablebutton-layoutPanel-buttonFlow',
    alignmentThumbnails: 'stylablebutton-layoutPanel-alignment',
    iconLabelSpacingSlider: 'stylablebutton-layoutPanel-iconLabelSpacing',
    buttonTypeDropDown: 'stylablebutton-layoutPanel-buttonType',
    buttonMaxContentBtnGroup: 'stylablebutton-layoutPanel-buttonMaxContent',
    buttonIsWrapTextToggle: 'stylablebutton-layoutPanel-wrapText',
    buttonTypeLabel: 'stylablebutton-layoutPanel-buttonTypeLabel',
    directionLTR: 'direction_ltr',
    directionRTL: 'direction_rtl',
  },
};

export const stylableSelectors = {
  root: '.root',
  container: '.root::container',
  label: '.root::label',
  icon: '.root::icon',
};

export const hugPropsToValidate: ComponentLayoutProps['size'] = {
  width: { type: 'maxContent' } as DS.UnitSize,
};

export const sizingBehaviorPresets = {
  Scale: scalePreset,
  RelativeWidth: {
    key: 'RelativeWidth',
    propsToApply: {
      componentLayout: {
        width: 'percentage',
        height: 'auto',
        minWidth: 'none',
        maxWidth: 'none',
        minHeight: 'px',
        maxHeight: 'none',
      },
    },
    propsToValidate: {
      componentLayout: ['width', 'minWidth', 'maxWidth'],
    },
  },
  Fixed: {
    key: 'Fixed',
    propsToApply: {
      componentLayout: {
        height: 'auto',
        width: 'px',
        minHeight: 'px',
        maxHeight: 'none',
        minWidth: 'none',
        maxWidth: 'none',
      },
    },
    propsToValidate: {
      componentLayout: ['width', 'minWidth', 'maxWidth', 'maxHeight'],
    },
  },
  Hug: {
    key: 'Hug',
    propsToApply: {
      componentLayout: {
        width: 'maxContent',
        minWidth: 'none',
        maxWidth: 'none',
        height: 'auto',
        minHeight: 'none',
        maxHeight: 'none',
      },
    },
    propsToValidate: {
      componentLayout: Object.keys(hugPropsToValidate),
    },
    demonstrationSrc: 'sizingBehavior/text-hug.v2.gif',
    isStyleScaleSupported: true,
  },
};

const commonPhysicalToLogicalAlignment: PhysicalToLogicalAlignment = {
  left: 'flex-start',
  right: 'flex-end',
  center: 'center',
  'space-between': 'space-between',
};

const TextAlignmentToLogicalAlignment: TextualToLogicalAlignment = {
  start: 'flex-start',
  end: 'flex-end',
  center: 'center',
  justify: 'space-between',
};

export const flexDirectionToLogicalAlignmentMap: Record<
  FlexDirection,
  Record<PhysicalAlignment, LogicalAlignment>
> = {
  row: commonPhysicalToLogicalAlignment,
  'row-reverse': {
    ...commonPhysicalToLogicalAlignment,
    left: 'flex-end',
    right: 'flex-start',
  },
  column: commonPhysicalToLogicalAlignment,
  'column-reverse': commonPhysicalToLogicalAlignment,
};

export const flexDirectionToTextAlignmentToLogicalAlignment: Record<
  FlexDirection,
  Record<TextAlignment, LogicalAlignment>
> = {
  row: TextAlignmentToLogicalAlignment,
  'row-reverse': {
    ...TextAlignmentToLogicalAlignment,
    start: 'flex-end',
    end: 'flex-start',
  },
  column: TextAlignmentToLogicalAlignment,
  'column-reverse': TextAlignmentToLogicalAlignment,
};

const commonLogicalToPhysicalAlignment: Record<
  LogicalAlignment,
  PhysicalAlignment
> = {
  'flex-start': 'left',
  'flex-end': 'right',
  center: 'center',
  'space-between': 'space-between',
};

const commonLogicalToTextAlignment: Record<LogicalAlignment, TextAlignment> = {
  'flex-start': 'start',
  'flex-end': 'end',
  center: 'center',
  'space-between': 'justify',
};

export const flexDirectionToPhysicalAlignmentMap: Record<
  FlexDirection,
  Record<LogicalAlignment, PhysicalAlignment>
> = {
  row: commonLogicalToPhysicalAlignment,
  'row-reverse': {
    ...commonLogicalToPhysicalAlignment,
    'flex-start': 'right',
    'flex-end': 'left',
  },
  column: commonLogicalToPhysicalAlignment,
  'column-reverse': commonLogicalToPhysicalAlignment,
};

export const flexDirectionToLogicalAlignmentToTextAlignment: Record<
  FlexDirection,
  Record<LogicalAlignment, TextAlignment>
> = {
  row: commonLogicalToTextAlignment,
  'row-reverse': {
    ...commonLogicalToTextAlignment,
    'flex-start': 'end',
    'flex-end': 'start',
  },
  column: commonLogicalToTextAlignment,
  'column-reverse': commonLogicalToTextAlignment,
};

export enum LabelOverflow {
  Wrap = 'wrap',
  Ellipsis = 'ellipsis',
}

export const UDP_BUTTON_SKIN = 'wixui.skins.StylableButton';
export const SCALE_PROPORTIONALLY_EXPERIMENT =
  'specs.responsive-editor.udpButtonScaleProportionally';
